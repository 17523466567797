import { j as e } from "./jsx-runtime-B6kdoens.js";
import * as R from "react";
import { g as r, c as t } from "./utils-CJ9afRe1.js";
import { W as y, I as P } from "./constants-dt6-92Rb.js";
const l = R.forwardRef(
  ({ className: a = "", as: s, wrapClassName: x = "", error: n = !1, type: p, variant: m, children: i, ...f }, u) => {
    const N = s || "input", d = r(i, "Prefix"), I = r(i, "Suffix"), j = r(i, "Label");
    return /* @__PURE__ */ e.jsxs(
      "div",
      {
        "aria-invalid": n,
        "aria-disabled": f.disabled,
        className: t(y({ variant: m, error: n, className: x })),
        children: [
          j,
          d,
          /* @__PURE__ */ e.jsx(
            N,
            {
              type: p,
              className: t(P({ variant: m, className: a })),
              ref: u,
              ...f
            }
          ),
          I
        ]
      }
    );
  }
);
l.displayName = "Input";
const o = ({ children: a, className: s = "" }) => /* @__PURE__ */ e.jsx("div", { className: t("text-gray-neutral-100", s), children: a });
o.displayName = "Prefix";
const c = ({ children: a, className: s = "" }) => /* @__PURE__ */ e.jsx("div", { className: t("text-gray-neutral-100", s), children: a });
c.displayName = "Suffix";
const S = Object.assign(l, { Prefix: o, Suffix: c });
export {
  S as I
};
